<template>
  <div class="pr" id="implement">
    <div class="luodi-ban">
      <div class="logo uf uf-ac cp" @click="$router.push({name: 'index'})">
        <img src="../../assets/img/logo-sp.png" alt="">
        <div class="logo-word ml-2 tac">
          <div class="fwb">开放式产品服务化支撑平台</div>
          <div class="f16 op-08"><span>——</span><span class="text-space">国家重点研发计划支持</span><span>——</span>
          </div>
        </div>
      </div>
      <div class="ad-word">品质服务&nbsp;&nbsp;折扣钜惠</div>
      <div class="date">
        <span>活动时间：</span>
        <span>2021.04.20-2021.05.20</span>
      </div>
      <div class="uf uf-pc">
        <div class="user-log">
          <div class="tac" v-if="!register">
            您好，请<el-button type="text" @click="openRegisterDialog"><span class="f16 fwb">注册</span></el-button>
            <!--        <div>-->
            <!--          <i class="el-icon-thumb f20"></i>-->
            <!--        </div>-->
          </div>
          <div v-else >
            欢迎你！{{ registerUserName }} , {{ registerUserMobile}}
            <el-button @click="logout" type="text" style="color: #6d6d6d">注销</el-button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="mrc">
        <div class="mt-3 uf uf-ac uf-pc">
          <div class="uf uf-ac">
            <i class="iconfont icon-hengxian one"></i>
            <span class="f20 fwb ser-tit uf-f1">商标软著服务</span>
            <i class="iconfont icon-hengxian"></i>
          </div>
        </div>
      </div>
      <div class="li-box uf fwrap-w tal">
        <template v-if="sbrzServiceList.length > 0">
          <div class="list" v-for="(item, index) in sbrzServiceList" :key="index">
              <div class="pic-box">
                <img :src="item.logoUrl ? item.logoUrl : require('@/assets/img/zwtp.png')"/>
              </div>
              <div class="ctx-box">
                <div class="dec fwb">
                  <span>{{ item.serviceName }}</span>
                </div>
                <div class="f22 ctx-box-price">
                  <span><span>¥</span>{{ item.supplyPrice  }}</span>
                  <span class="original-price">{{ item.price }}</span>
                  <span style="font-size: 5px; color: gray"></span>
                </div>
                <div style="padding: 0 14px">
                  <div class="f12">
                    <span class="op-07">服务分类：{{ item.itemName }}</span>
                    <span></span>
                  </div>
                </div>
                <div class="company mb-1 mt-1">
                  <div class="uf uf-ac fwrap-w" style="font-size: 10px">
                    <div class="mr-1 mb-1" style="padding: 0 5px;border: 1px solid #007bff;border-radius: 3px;color: #007bff">
                      自营
                    </div>
                    <div class="mr-1 mb-1" style="padding: 0 5px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                      严选
                    </div>
                    <div class="mb-1" style="padding: 0 5px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                      服务质保
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </template>
      <template v-else>
          <div class="tac wi100" style="height: 300px">
              <icon-svg class="mt-3" style="font-size: 200px" name="wushuju"></icon-svg>
              <div class="f14" style="color: #909399">暂无数据</div>
          </div>
        </template>
      </div>
    </div>
    <div>
      <div class="mrc">
        <div class="mt-3 uf uf-ac uf-pc">
          <div class="uf uf-ac">
            <i class="iconfont icon-hengxian one"></i>
            <span class="f20 fwb ser-tit uf-f1">版权服务</span>
            <i class="iconfont icon-hengxian"></i>
          </div>
        </div>
      </div>
      <div class="li-box uf fwrap-w tal">
        <template v-if="bqService.length > 0">
          <div class="list" v-for="(item, index) in bqService" :key="index">
              <div class="pic-box">
                <img :src="item.logoUrl ? item.logoUrl : require('@/assets/img/zwtp.png')"/>
              </div>
              <div class="ctx-box">
                <div class="dec fwb">
                  <span>{{ item.serviceName }}</span>
                </div>
                <div class="f22 ctx-box-price">
                  <span><span>¥</span>{{ item.supplyPrice  }}</span>
                  <span class="original-price">{{ item.price }}</span>
                  <span style="font-size: 5px; color: gray"></span>
                </div>
                <div style="padding: 0 14px">
                  <div class="f12">
                    <span class="op-07">服务分类：{{ item.itemName }}</span>
                    <span></span>
                  </div>
                </div>
                <div class="company mb-1 mt-1">
                  <div class="uf uf-ac fwrap-w" style="font-size: 10px">
                    <div class="mr-1 mb-1" style="padding: 0 5px;border: 1px solid #007bff;border-radius: 3px;color: #007bff">
                      自营
                    </div>
                    <div class="mr-1 mb-1" style="padding: 0 5px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                      严选
                    </div>
                    <div class="mb-1" style="padding: 0 5px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                      服务质保
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </template>
        <template v-else>
          <div class="tac wi100" style="height: 300px">
            <icon-svg class="mt-3" style="font-size: 200px" name="wushuju"></icon-svg>
            <div class="f14" style="color: #909399">暂无数据</div>
          </div>
        </template>
      </div>
    </div>
    <div>
      <div class="mrc">
        <div class="mt-3 uf uf-ac uf-pc">
          <div class="uf uf-ac">
            <i class="iconfont icon-hengxian one"></i>
            <span class="f20 fwb ser-tit uf-f1">认证服务</span>
            <i class="iconfont icon-hengxian"></i>
          </div>
        </div>
      </div>
      <div class="zz-service fwrap-w">
        <div class="li-box uf fwrap-w tal">
          <template v-if="rzService.length > 0">
            <div class="list" style="padding: 10px">
              <div class="uf uf-ac">
                <div class="pic-box">
                  <img :src="rzService[0].logoUrl ? rzService[0].logoUrl : require('@/assets/img/zwtp.png')"/>
                </div>
                <div class="ctx-box" style="min-width: 150px">
                  <div class="dec fwb" style="padding-right: 0;">
                    <span>{{ rzService[0].serviceName }}</span>
                  </div>
                  <div style="padding: 0 14px">
                    <div class="f12">
                      <span class="op-07">服务分类：{{ rzService[0].itemName }}</span>
                      <span></span>
                    </div>
                  </div>
                  <div class="company mb-1 mt-1" style="padding-right: 0">
                    <div class="uf uf-ac fwrap-w" style="font-size: 10px">
                      <div class="mr-1 mb-1" style="padding: 0 5px;border: 1px solid #007bff;border-radius: 3px;color: #007bff">
                        自营
                      </div>
                      <div class="mr-1 mb-1" style="padding: 0 5px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                        严选
                      </div>
                      <div class="mb-1" style="padding: 0 5px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                        服务质保
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="area">
                <div class="small">八大技术领域:电子信息、生物与新医药、航空航天、新材料,、高技术服务、新能源与节能、资源与环境、先进制造与自动化</div>
                <div class="f22 ctx-box-price">
                  <span><span>¥</span>{{ rzService[0].supplyPrice  }}</span>
                  <span class="original-price">{{ rzService[0].price }}</span>
                  <span style="font-size: 5px; color: gray"></span>
                </div>
              </div>
              <div class="area">
                <div class="small">其他领域：</div>
                <div class="f22 ctx-box-price">
                  <span><span>¥</span>{{ rzService[1].supplyPrice  }}</span>
                  <span class="original-price">{{ rzService[1].price }}</span>
                  <span style="font-size: 5px; color: gray"></span>
                </div>
              </div>
            </div>
            <div class="list">
                <div class="pic-box">
                  <img :src="rzService[2].logoUrl ? rzService[2].logoUrl : require('@/assets/img/zwtp.png')"/>
                </div>
                <div class="ctx-box">
                  <div class="dec fwb">
                    <span>{{ rzService[2].serviceName }}</span>
                  </div>
                  <div class="f22 ctx-box-price">
                    <span><span>¥</span>{{ rzService[2].supplyPrice  }}</span>
                  <span class="original-price">{{ rzService[2].price }}</span>
                  <span style="font-size: 5px; color: gray"></span>
                  </div>
                  <div style="padding: 0 14px">
                    <div class="f12">
                      <span class="op-07">服务分类：{{ rzService[2].itemName }}</span>
                      <span></span>
                    </div>
                  </div>
                  <div class="company mb-1 mt-1">
                    <div class="uf uf-ac fwrap-w" style="font-size: 10px">
                      <div class="mr-1 mb-1" style="padding: 0 5px;border: 1px solid #007bff;border-radius: 3px;color: #007bff">
                        自营
                      </div>
                      <div class="mr-1 mb-1" style="padding: 0 5px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                        严选
                      </div>
                      <div class="mb-1" style="padding: 0 5px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                        服务质保
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </template>
          <template v-else>
            <div class="tac wi100" style="height: 300px">
              <icon-svg class="mt-3" style="font-size: 200px" name="wushuju"></icon-svg>
              <div class="f14" style="color: #909399">暂无数据</div>
            </div>
          </template>
        </div>
        <div class="rule">
          <h3>活动规则：</h3>
          <div class="rule-detail">
            <div>1、活动时间：2021.04.20-2021.05.20</div>
            <div>2、以上活动产品价格只可在活动期间享用。</div>
            <div>3、活动产品优惠价只限本页面注册用户享受，下单前请咨询客服。</div>
            <div>4、最终解释权归《开放式产品服务化支撑平台》所有。</div>
          </div>
        </div>
        <div class="tac pt-2 " style="color: #999999">
          <span class="f14">http://127.0.0.1</span>
        </div>
        <a class="fixed-right hidden-md-and-up" href="tel:0531-82609032">
          <i class="iconfont icon-tel"></i>
        </a>
        <el-popover placement="left" width="260" trigger="hover">
          <div class="p-1 f18 fwb">
            电话：0531-82609032
          </div>
          <div class="fixed-right hidden-sm-and-down" slot="reference">
            <i class="iconfont icon-tel"></i>
          </div>
        </el-popover>
      </div>
    </div>
    <el-dialog
      :visible.sync="registerVisible"
      title="注册"
      :width="isMobile ? '90%' : '30%'"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      @close="cancelRegister">
      <div>
        <el-form :model="user" ref="user" :rules="dataRule">
          <el-row :gutter="10">
            <el-col>
              <el-form-item prop="name">
                <el-input v-model="user.name" placeholder="姓名"></el-input>
              </el-form-item>
            </el-col>
          </el-row><el-row :gutter="10">
          <el-col>
            <el-form-item prop="enterpriseName">
              <el-input v-model="user.enterpriseName" placeholder="公司名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
          <el-row :gutter="10">
            <el-col>
              <el-form-item prop="mobile">
                <el-input v-model="user.mobile" placeholder="手机号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col>
              <el-form-item>
              <div v-if="isMobile"><slider-mobile ref="slider"></slider-mobile></div>
                <div v-else><slider ref="slider"></slider></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="smsCode">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-input prefix-icon="el-icon-key" v-model="user.smsCode" placeholder="短信验证码"></el-input>
              </el-col>
              <el-col :span="12">
                <div class="qrcode">
                  <template v-if="smsSuc">
                    <el-button type="danger"><span class="f8">{{ smsTime }}</span></el-button>
                  </template>
                  <template v-else>
                    <el-button type="primary" slot="reference" plain @click="getMsgCode">获取验证码</el-button>
                  </template>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item class="tac">
            <el-button class="login-btn" type="primary" @click="doSub" :loading="loading">注册</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Slider from '../common/components/slider'
  import SliderMobile from '../common/components/slider-mobile'
  import {encode64,userSystemAgent} from '@/utils'
  import {isMobile, isEmail} from '../../utils/validate'
  import {checkIsNull} from '../../utils'
  import shangbiaozhuce from '../../assets/img/implement/shangbiaozhuce.jpg'
  import ruanjianzhuzuoquandengji from '../../assets/img/implement/ruanjianzhuzuoquandengji.jpg'
  import shiyongxinxingzhuanli from '../../assets/img/implement/shiyongxinxingzhuanli.jpg'
  import AAAqiyexinyongpingji from '../../assets/img/implement/AAAqiyexinyongpingji.jpg'
  import famingzhuanli from '../../assets/img/implement/famingzhuanli.jpg'
  import guogaoxinshenbaozixun from '../../assets/img/implement/guogaoxinshenbaozixun.jpg'
  import waiguanshejizhuanli from '../../assets/img/implement/waiguanshejizhuanli.jpg'
export default {
  name: 'implement',
  components: {Slider,SliderMobile},
  data() {
    const checkSmsCode = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('短信验证码不能为空'))
      } else {
        callback()
      }
    }
    const checkMobile = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入手机号码'))
      } else if (!isMobile(value)) {
        return callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    }
    const validatePassword1 = (rule, value, callback) => {
      const pwd = /(?=.*[a-zA-Z])(?=.*\d)(?=.*[.#@!~%^&*])[a-zA-Z\d.#@!~%^&*]{6,16}/
      if (value === '') {
        callback(new Error('密码不能为空'))
      } else if (!pwd.test(value)) {
        callback(new Error('密码应为6~16个字符，包含字母、数字和特殊字符(.#@!~%^&*)'))
      } else {
        callback()
      }
    }
    const checkEmail = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入邮箱地址'))
      } else if (!isEmail(value)) {
        return callback(new Error('邮箱地址格式不正确'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.dataForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      isMobile: false,
      smsSuc: false,
      register: false,
      registerVisible: false,
      loading: false,
      user: {
        name: '',
        enterpriseName: '',
        mobile: '',
        smsCode: '',
      },
      registerUserName: '',
      registerUserMobile: '',
      smsTime: '',
      dataRule: {
        name: [
          {required: true, message: '昵称不能为空', trigger: 'blur'}
        ],
        password: [
          {required: true, validator: validatePassword1, trigger: 'blur'}
        ],
        checkPassword: [
          {required: true, validator: validatePassword, trigger: 'blur'}
        ],
        mobile: [
          {required: true, validator: checkMobile, trigger: 'blur'}
        ],
        email: [
          {required: true, validator: checkEmail, trigger: 'blur'}
        ],
        smsCode: [{validator: checkSmsCode, trigger: 'blur'}
        ]
      },
      sbrzServiceList:[{
        itemName: '企业工商服务',
        serviceName: '专家辅助商标注册',
        price: '899',
        supplyPrice: '680',
        logoUrl: shangbiaozhuce
      },
      {
        itemName: '企业工商服务',
        serviceName: '无忧商标注册',
        price: '1280',
        supplyPrice: '1180',
        logoUrl: shangbiaozhuce
        },
        {
          itemName: '企业工商服务',
          serviceName: '自助商标注册',
          price: '400',
          supplyPrice: '300',
          logoUrl: shangbiaozhuce
        },
        {
          itemName: '企业工商服务',
          serviceName: '软件著作权登记',
          price: '499',
          supplyPrice: '400',
          logoUrl: ruanjianzhuzuoquandengji
        }],
      bqService: [{
        itemName: '知识产权服务',
        serviceName: '外观设计专利申请',
        price: '400',
        supplyPrice: '300',
        logoUrl: waiguanshejizhuanli
      },{
          itemName: '知识产权服务',
          serviceName: 'GUI外观设计专利申请',
          price: '1500',
          supplyPrice: '1200',
          logoUrl: waiguanshejizhuanli
        },{
          itemName: '知识产权服务',
          serviceName: '实用新型专利申请',
          price: '2500',
          supplyPrice: '2250',
          logoUrl: shiyongxinxingzhuanli
        },{
          itemName: '知识产权服务',
          serviceName: '发明专利申请',
          price: '5000',
          supplyPrice: '4500',
          logoUrl: famingzhuanli
        }],
      rzService: [{
        itemName: '质量管理认证',
        serviceName: '国高新申报咨询服务',
        price: '20000',
        supplyPrice: '17000',
        logoUrl: guogaoxinshenbaozixun
      },{
        itemName: '质量管理认证',
        serviceName: '国高新申报咨询服务',
        price: '30000',
        supplyPrice: '28000',
        logoUrl: guogaoxinshenbaozixun
      },{
          itemName: '信用评估服务',
          serviceName: 'AAA企业信用评价',
          price: '4888',
          supplyPrice: '2666',
          logoUrl: AAAqiyexinyongpingji
        }]
    }
  },
  created() {
    //判断是否移动端
    let ua = userSystemAgent()
    if (ua === 'isAndroid'||ua ==='isIOS') {
      this.isMobile = true
    }
    let registerUserName = localStorage.getItem('registerUserName')
    let registerUserMobile = localStorage.getItem('registerUserMobile')
    if (!checkIsNull(registerUserName)) {
      this.register = true
      this.registerUserName = registerUserName
      this.registerUserMobile = registerUserMobile
    }
  },
  mounted() {

  },
  methods: {
    doSub () {
      let _this = this
      this.$refs['user'].validate((valid) => {
        if (valid && !this.loading) {
          this.loading = true
          this.$http({
            url: _this.$http.adornUrl(this.$api.SYS.MOBILE_REGISTER),
            method: 'post',
            data: _this.$http.adornData({
              'name': _this.user.name,
              'enterpriseName': _this.user.enterpriseName,
              'mobile': _this.user.mobile,
              'smsCode': encode64(this.user.smsCode),
              'smsTerminal': 'SMS_TERMINAL_REGISTER',
              'source': 'NDJLH0420'
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.register = true
              this.loading = false
              this.registerVisible = false
              this.registerUserName = data.userData.name
              this.registerUserMobile = data.userData.mobile
              localStorage.setItem('registerUserName', data.userData.name)
              localStorage.setItem('registerUserMobile', data.userData.mobile)
              this.$message.success('登录成功')
            } else {
              this.$message.error(data.msg)
              this.$nextTick(() => {
                this.loading = false
              })
            }
          })
        }
      })
    },
    logout () {
      this.user.name = ''
      this.user.mobile = ''
      this.user.smsCode = ''
      this.user.enterpriseName = ''
      this.smsSuc = false
      this.register = false
    },
    openRegisterDialog() {
      this.user.name = ''
      this.user.mobile = ''
      this.user.smsCode = ''
      this.user.enterpriseName = ''
      this.smsSuc = false
      this.countdown(0)
      this.registerVisible = true
    },
    cancelRegister () {
      this.registerVisible = false
    },
    getMsgCode () {
      if(this.$refs['slider']) {
        // 未通过验证时，提示错误信息并返回
        if (!this.$refs['slider'].confirmSuccess) {
          this.$message.error('请拖动滑块验证')
          return
        }
      }
      let _this = this
      this.$refs['user'].validateField('mobile', (mobileMsg) => {
        if (mobileMsg === '') {
          _this.$http({
            url: _this.$http.adornUrl(_this.$api.SYS.VALID_CODE),
            method: 'post',
            data: _this.$http.adornData({
              'mobile': _this.user.mobile,
              'smsTerminal': 'SMS_TERMINAL_REGISTER'
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message.success('短信发送成功，请注意查收')
              _this.smsSuc = true
              _this.smsTime = '60s后可重新获取'
              _this.countdown(60)
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    },
    countdown (time) {
      if (time < 1) {
        this.smsSuc = false
        return
      }
      this.smsSuc = true
      this.smsTime = time + 's后可重新获取'
      setTimeout(this.countdown, 1000, --time)
    },
    //质量管理服务
    // zlglService() {
    //   this.businessLoading = true
    //   this.$http({
    //     url: this.$http.adornUrl(this.$api.PUB.SERVICE_LIST),
    //     method: 'post',
    //     params: this.$http.adornParams({
    //       'page': 1,
    //       'limit': 5,
    //       'itemCode': '50'
    //     })
    //   }).then(({data}) => {
    //     if (data && data.code === 0) {
    //       this.sbrzServiceList = data.page.list
    //     } else {
    //       this.dataList = []
    //       this.totalPage = 0
    //     }
    //     this.businessLoading = false
    //   })
    // },
  },
  watch: {},
}
</script>
<style scoped>
  /*.list-box .pic-box img {*/
    /*width: 100%;*/
    /*height: 190px;*/
  /*}*/
    .icon-hengxian {
        /*color: #cccccc;*/
        background-image: -webkit-linear-gradient(left, rgb(0, 111, 205), rgba(158, 208, 255, 0));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 24px;
    }

    .icon-hengxian.one {
        /*color: #cccccc;*/
        transform: rotate(180deg);
    }

    .ser-tit {
        margin: 10px 20px;
    }

    .mrc {
        max-width: 1300px;
        width: auto;
    }

    .original-price {
        text-decoration: line-through;
        color: #a6a6a6;
        font-size: 14px;
        margin-left: 6px;
        font-weight: normal;
    }

    .ctx-box-price {
        padding: 6px 14px 0 10px;
    }


    .zz-service .li-box:first-child .list .small {
        font-weight: normal;
        font-size: 12px;
        opacity: .6;
        padding: 5px 10px;
        text-align: justify;
    }

    .ctx-box-price {
        font-size: 18px;
        font-weight: bold;
    }

    .area {
        margin: 6px;
        border: 1px dashed #cccccc;
    }

    .zz-service .li-box .list:first-child {
        width: 50%;
        padding-top: 10px;
    }

    .zz-service .li-box .list:first-child .pic-box {
        width: 230px;
    }

    .luodi-ban {
        width: 100%;
        height: 600px;
        background: url("../../assets/img/luodi-ban.jpg") no-repeat;
        background-size: cover;
        background-position: bottom center;
        text-align: center;

    }

    .luodi-ban .ad-word {
        font-size: 6vw;
        color: #fff;
        padding-top: 80px;
        font-weight: bold;
        text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
    }

    .date {
        width: 380px;
        margin: 12px auto;
        color: #f7d8d9;
        font-size: 18px;
        font-weight: bold;
        height: 80px;
        padding-top: 24px;
        background: url("../../assets/img/date.png") no-repeat;
        background-size: 380px;
        background-position: bottom center;
        text-align: center;
    }

    .rule {
        max-width: 1300px;
        margin: 0 auto;
        background: #dbedf4;
        padding: 0 10px 10px;
        border: 1px dashed #87bcf4;
    }

    a.fixed-right {
        text-decoration: none;
    }

    .rule .rule-detail > div {
        line-height: 24px;
    }

    .fixed-right {
        position: fixed;
        right: 15px;
        bottom: 150px;
        background: #ffffff;
        border-radius: 4px 0 0 4px;
        padding: 12px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .1);

    }

    .fixed-right:active {
        background: #d2d2d2;
    }

    .fixed-right .iconfont {
        font-size: 22px;
        color: #409eff;
    }

    .luodi-ban .logo {
        max-width: 1300px;
        margin: 0 auto;
        padding-top: 15px;
    }

    .luodi-ban .logo img {
        width: 3rem;
    }

    .luodi-ban .logo .logo-word {
        font-size: 2rem;
        text-shadow: 0 4px 3px rgba(73, 73, 73, 0.1);
        color: #ffffff;
    }

    .login {
        /*width: 900px;*/
        /*margin: 0 auto 0 auto;*/
        padding: 24px;
        border-radius: 8px;
        background: #ffffff;
        box-shadow: 0 0 10px rgba(36, 36, 36, 0.3)
    }

    /*.qrcode {*/
    /*    width: 100px;*/
    /*}*/

    .login-btn {
        width: 100px;
    }

    .form-wrap >>> .el-form-item {
        margin-bottom: 0;
    }
    .user-log {
      padding: 0 12px;
      background: rgba(255,255,255,.8);
      border-radius: 30px;
      box-shadow: 0 0 12px rgba(0,0,0,.2);

    }
  /*服务页*/
  .li-box {
    max-width: 1325px;
    margin: 0 auto;
  }
  .li-box .list {
    width: 245px;
    height: auto;
    margin: 10px;
    background: #ffffff;
    line-height: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,.08);
  }
    @media only screen and (min-width: 1301px) {
      .li-box {
        justify-content: center;
      }
    }
    @media only screen and (max-width: 1300px) {
        .icon-hengxian {
            font-size: 20px;
        }

        .mrc {
            max-width: none;
            width: 100vw;
            margin: 0;
        }

        .rule {
            max-width: none;
            /*width: 100%;*/
            margin: 20px 14px;
        }

        .rule .rule-detail > div {
            line-height: 24px;
            font-size: 13px;
        }

        .fixed-right {
            right: 0;
            bottom: 30vh;
        }

        .luodi-ban .ad-word {
            font-size: 8vw;
            color: #fff;
            padding-top: 50px;
            font-weight: bold;
            text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
        }

        .li-box .list {
            width: calc(25% - 12px);
            height: auto;
            margin: 6px;
        }

        .li-box {
            padding: 0 12px;
        }

        .zz-service .li-box .list:first-child .pic-box {
            width: 160px;
        }

        .luodi-ban {
            height: 460px;
        }
        .login {
            margin: 0 auto 0 auto;

        }
    }

    @media only screen and (max-width: 1000px) {
        #implement {
            padding-bottom: 240px;
        }
        .form-wrap {
            flex-direction: column;
        }
        .logo {
            justify-content: center;
        }
        .login {
            /*position: absolute;*/
            left: 0;
            bottom: 0;
            width: 100%;
            margin: 0 auto 0 auto;
        }

        .form-wrap >>> .el-form-item {
            margin-bottom: 16px;
            width: 90%;
        }

        .luodi-ban {
            height: 440px;
        }

        .luodi-ban .logo {
            width: 100%;
            margin: 0;
            padding-top: 15px;
        }

        .login-btn {
            width: 100%;
        }

        .luodi-ban .logo img {
            width: 2rem;
        }

        .luodi-ban .logo .logo-word {
            font-size: 1.4rem;
            text-shadow: 0 4px 3px rgba(73, 73, 73, 0.1);
            color: #ffffff;
        }

        .luodi-ban .logo .logo-word .text-space {
            font-size: .8rem;
        }
    }

    @media only screen and (max-width: 800px) {
        .icon-hengxian {
            font-size: 20px;
        }

        .mrc {
            max-width: none;
            width: 100vw;
            margin: 0;
        }

        .zz-service .li-box .list:first-child {
            width: 60%;
            padding-top: 10px;
        }

        .li-box .list {
            width: calc(33.3% - 12px);
            height: auto;
        }

        .li-box {
            padding: 0 12px;
        }

        .luodi-ban {
            width: 100%;
            height: 440px;
            background: url("../../assets/img/luodi-ban.jpg") no-repeat;
            background-size: cover;
            background-position: bottom center;
            text-align: center;

        }
    }

    @media only screen and (max-width: 500px) {
        .icon-hengxian {
            font-size: 16px;
        }

        .mrc {
            max-width: none;
            width: 100vw;
            margin: 0;
        }

        .li-box .list {
            width: calc(50% - 12px);
            height: auto;
        }

        .li-box {
            padding: 0 6px;
        }

        .zz-service .li-box .list:first-child {
            width: 100%;
            padding-top: 10px;
        }

        .luodi-ban {
            width: 100%;
            height: 360px;
            background: url("../../assets/img/luodi-ban.jpg") no-repeat;
            background-size: 800px 606px;
            background-position: bottom center;
            margin-bottom: -40px;
        }

        .luodi-ban .ad-word {
            font-size: 10vw;
            color: #fff;
            padding-top: 20px;
            font-weight: bold;
            text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
        }

        .date {
            width: 330px;
            margin: 12px auto;
            color: #f7d8d9;
            font-size: 15px;
            font-weight: bold;
            height: 60px;
            padding-top: 12px;
            background: url("../../assets/img/date.png") no-repeat;
            background-size: 330px;
            background-position: bottom center;
            text-align: center;
        }

    }


    .li-box .pic-box {
      width: 100%;
      height: auto;
    }
    .li-box .pic-box img {
      width: 100%;
      height: auto;
    }
    .li-box .ctx-box .dec{
      padding: 0 14px;
      /*margin: 10px 0;*/
      font-size: 16px;
      overflow: hidden;
      /*height: 40px;*/
    }
    .ctx-link:hover{
      color: #3987dd;
      text-decoration: underline;
      opacity: 1;
    }
    .ctx-box-price{
      padding: 18px 14px 0 10px;
      color: #fe3b25;
    }
    .li-box .ctx-box .company {
      padding: 0 12px;
      margin-top: 8px;
    }
    .detail-box {
      /*width: 1180px;*/
      /*margin: 0 auto;*/
    }
    .detail-box .logo {
      width: 300px;
      height: 300px;
    }
    .detail-box .detail .label {
      font-size: 12px;
      opacity: .6;
      width: 110px;
    }
</style>
